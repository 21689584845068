.fadeInNext {
	animation: 0.5s fadeInNext forwards;
}

.fadeOutNext {
	animation: 0.5s fadeOutNext forwards;
}
.fadeInPrevious {
	animation: 0.5s fadeInPrevious forwards;
}

.fadeOutPrevious {
	animation: 0.5s fadeOutPrevious forwards;
}

@keyframes fadeInNext {
	from {
		opacity: 0;
		transform: translate(0px, 50%);
	}
	to {
		opacity: 1;
		transform: translate(0px, 0);
	}
}
@keyframes fadeInPrevious {
	from {
		opacity: 0;
		transform: translate(0px, -50%);
	}
	to {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

@keyframes fadeOutNext {
	from {
		opacity: 1;
		transform: translate(0px, 0px);
	}
	to {
		transform: translate(0px, -50%);
		opacity: 0;
	}
}
@keyframes fadeOutPrevious {
	from {
		opacity: 1;
		transform: translate(0px, 0%);
	}
	to {
		transform: translate(0px, 50%);
		opacity: 0;
	}
}

.carousel-indicators li {
	cursor: pointer;
}
.carousel-indicators {
	list-style-type: none;
}
.icon-menu svg {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
}
.preloader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #111;
	transform-origin: bottom;
	z-index: 111111;
}
.preloader .black_wall {
	height: 100%;
	background-color: #222;
	transform-origin: top;
	animation: preloader_slide 0.5s ease-in-out 0s 1 normal both;
}
.preloader .loader {
	width: 100%;
	height: 3px;
	position: absolute;
	border-radius: 2px;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
	background-color: #ffc41f;
	transform-origin: left;
	animation: loader_running 3s ease-in-out 1s infinite normal both;
}
.preloader.off {
	animation: preloader_slide 0.5s ease-in-out 0s 1 reverse both;
}
@keyframes loader_running {
	0% {
		transform: scaleX(0);
		transform-origin: left;
	}
	49% {
		transform: scaleX(1);
		transform-origin: left;
	}
	50% {
		transform: scaleX(1);
		transform-origin: right;
	}
	100% {
		transform-origin: right;
		transform: scaleX(0);
	}
}
@keyframes preloader_slide {
	from {
		transform: scaleY(0);
	}
	to {
		transform: scaleY(1);
	}
}
