/*
Template Name: Tunis - Personal Portfolio
Author: celtano
Author URI: http://themeforest.net/user/celtano
Description: Tunis - Personal Portfolio
Version: 1.0
*/

/*------------------------------------------------------------------
[Table of contents]
	+ General
	    - Miscellaneous
		- Light Body
		- Buttons
		- Page Title
	+ Header
		- Desktop Navigation
		- Mobile Navigation
	+ Pages
		- Homepage
		+ About
		    - Personal Info List
			- Box Stats
			- Resume
			- Skills
		+ Portfolio
		    - Grid
		    + Slideshow
                - Embedded Videos
                - Carousel
                - Navigation
        + Contact
            - Left Side
            - Contact Form
		+ Blog
            - Latest Posts
            - Pagination
	- Responsive Design Styles
-------------------------------------------------------------------*/

/* [ GENERAL ] */
/*================================================== */

/*** Miscellaneous ***/

html {
	overflow-x: hidden;
	height: 100%;
}

body {
	font-family: "Poppins", sans-serif;
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	line-height: 1.6;
	position: relative;
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
	background-color: #111;
}

.poppins-font {
	font-family: "Poppins", sans-serif;
}

.open-sans-font {
	font-family: "Open Sans", sans-serif;
}

hr.separator {
	border-top: 1px solid #252525;
	margin: 70px auto 55px;
	max-width: 40%;
}

.ft-wt-600 {
	font-weight: 600;
}

.mb-30 {
	margin-bottom: 30px;
}

.no-transform {
	transform: none !important;
}

.custom-title {
	padding-bottom: 22px;
	font-size: 26px;
}

/*** Light Body ***/

.light {
	color: #666;
	background-color: #fff;
}

.home.light .home-details h1 span,
.light .button .button-text {
	color: #666;
}

.light .button:hover .button-text {
	color: #fff;
}

.home.light .home-details h6 {
	color: #777;
}

.home.light .bg {
	background-image: url(../img/light.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.light .preloader .black_wall {
	background-color: #eee;
}

.light .preloader {
	background-color: #fff;
}

.light .title-section h1 {
	color: #666;
}

.light .title-bg {
	color: rgba(30, 37, 48, 0.07);
}

.light.about .box-stats {
	border: 1px solid #ddd;
}

.light.about .c100,
.light.about .resume-box .time {
	background-color: #eee;
}

.light.about .resume-box .time,
.light.about .resume-box p {
	color: #666;
}

.light.about .resume-box .place:before {
	background-color: #666;
}

.light.about .resume-box li:after {
	border-left: 1px solid #ddd;
}

.light.about .c100 > span {
	color: #666;
}

.light.about .c100:after {
	background-color: #fff;
}

.light.portfolio .slideshow figure {
	background-color: #fff;
}

.light.portfolio .slideshow ul > li:after {
	background: rgba(255, 255, 255, 0.77);
}

.light.portfolio .slideshow {
	background: rgba(0, 0, 0, 0.7);
}

.light.contact .contactform input[type="text"],
.light.contact .contactform input[type="email"],
.light.contact .contactform textarea {
	background-color: #fff;
	border: 1px solid #ddd;
	color: #666;
}

.light.contact ul.social li a {
	color: #666;
	background: #eee;
}

.light.contact ul.social li a:hover {
	color: #fff;
}

.light.blog .post-content {
	background-color: #f2f2f2;
}

.light.blog .post-content .entry-header h3 a {
	color: #666;
}

.light.blog .page-link {
	color: #666;
	background-color: #eee;
	border: 1px solid #eee;
}

.light.blog-post .meta {
	color: #888;
}

.light.blog .page-item.active .page-link {
	color: #fff;
}

.light hr.separator {
	border-top: 1px solid #ddd;
}

@media (min-width: 992px) {
	.light .header ul.icon-menu li.icon-box {
		background-color: #eee;
	}

	.light .header ul.icon-menu li.icon-box i {
		color: #666;
	}

	.light .header ul.icon-menu li.icon-box.active,
	.light .header ul.icon-menu li.icon-box:hover,
	.light .header ul.icon-menu li.icon-box.active i,
	.light .header ul.icon-menu li.icon-box:hover i {
		color: #fff;
	}
}

@media (max-width: 991px) {
	.light .main-img-mobile {
		border: 4px solid #eee;
	}

	.light #menuToggle {
		border: 1px solid #ddd;
	}

	.light #menuToggle,
	.light #menu {
		background: #eee;
	}

	.light #menuToggle > span,
	.light #menuToggle input:checked ~ span {
		background: #666;
	}

	.light #menu li a {
		color: #666;
	}

	.light #menu li:after {
		background: #ddd;
	}

	.light.portfolio .slideshow nav {
		background: #eee;
		border-bottom: 1px solid #ddd;
	}

	.light.portfolio .slideshow nav span img {
		display: none;
	}

	.light.portfolio .slideshow nav span.nav-next {
		background-image: url("../img/projects/navigation/right-arrow.png");
		background-size: cover;
	}

	.light.portfolio .slideshow nav span.nav-prev {
		background-image: url("../img/projects/navigation/left-arrow.png");
		background-size: cover;
	}

	.light.portfolio .slideshow nav span.nav-close {
		background-image: url("../img/projects/navigation/close-button.png");
		background-size: cover;
	}

	.light.portfolio .slideshow nav span.nav-next,
	.light.portfolio .slideshow nav span.nav-prev {
		top: 51px;
	}

	.light.portfolio .slideshow {
		background: #fff;
	}
}

@media (max-width: 576px) {
	.light .title-section {
		background: #eee;
		border-bottom: 1px solid #ddd;
	}

	.light #menuToggle {
		border: 0;
	}

	.light.portfolio .slideshow nav span.nav-next,
	.light.portfolio .slideshow nav span.nav-prev {
		top: 31px;
	}
}
/*** Buttons ***/
.button {
	overflow: hidden;
	display: inline-block;
	line-height: 1.4;
	border-radius: 35px;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	user-select: none;
	transition: 250ms all ease-in-out;
	text-transform: uppercase;
	text-decoration: none !important;
	position: relative;
	z-index: 1;
	padding: 16px 70px 16px 35px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	background-color: transparent;
	outline: none !important;
}

.button .button-text {
	position: relative;
	z-index: 2;
	color: #fff;
	transition: 0.3s ease-out all;
}

.button:before {
	z-index: -1;
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transform: translateX(100%);
	transition: 0.3s ease-out all;
}

.button:hover .button-text {
	color: #ffffff;
}

.button:hover:before {
	transform: translateX(0);
}

.button-icon {
	position: absolute;
	right: -1px;
	top: -1px;
	bottom: 0;
	width: 35px;
	height: 35px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 19px;
	color: #ffffff;
	border-radius: 50%;
}

/*** Page Title ***/

.title-section {
	margin: 0 auto;
	width: 100%;
	position: relative;
	padding: 80px 0;
}

.title-section h1 {
	font-size: 56px;
	font-weight: 900;
	color: #fff;
	text-transform: uppercase;
	margin: 0;
}

.title-bg {
	font-size: 110px;
	left: 0;
	letter-spacing: 10px;
	line-height: 0.7;
	position: absolute;
	right: 0;
	top: 50%;
	text-transform: uppercase;
	font-weight: 800;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	color: rgba(255, 255, 255, 0.07);
}

/* [ HEADER ] */
/*================================================== */

/*** Desktop Navigation ***/

@media (min-width: 992px) {
	/*** Navigation ***/

	.header {
		position: fixed;
		right: 30px;
		bottom: 0;
		z-index: 3;
		display: flex;
		align-items: center;
		height: calc(100vh - 200px);
		top: 100px;
		opacity: 1;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
	}

	.header.hide-header {
		z-index: 0 !important;
		opacity: 0;
	}

	.header ul.icon-menu {
		margin: 0;
		padding: 0;
	}

	.header ul.icon-menu li.icon-box {
		width: 50px;
		height: 50px;
		list-style: none;
		position: relative;
		display: flex;
		align-items: center;
		transition: 0.3s;
		margin: 20px 0;
		border-radius: 50%;
		background: #2b2a2a;
	}

	.header ul.icon-menu li.icon-box i {
		color: #ddd;
		font-size: 19px;
		transition: 0.3s;
	}

	.header ul.icon-menu li.icon-box.active,
	.header ul.icon-menu li.icon-box:hover,
	.header ul.icon-menu li.icon-box.active i,
	.header ul.icon-menu li.icon-box:hover i {
		color: #fff;
	}

	.header .icon-box h2 {
		font-size: 15px;
		font-weight: 500;
	}

	.header .icon-box a {
		display: block;
		padding: 0;
		width: 50px;
		height: 50px;
	}

	.header .icon-box i.fa {
		position: absolute;
		pointer-events: none;
	}

	.header .icon-menu i {
		left: 0;
		right: 0;
		margin: 0 auto;
		display: block;
		text-align: center;
		font-size: 35px;
		top: 15px;
	}

	.header .icon-box h2 {
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		color: #fff;
		line-height: 50px;
		font-weight: 500;
		transition: all 0.3s;
		border-radius: 30px;
		text-transform: uppercase;
		padding: 0 25px 0 30px;
		height: 50px;
	}

	.icon-box {
		cursor: pointer;
	}
	.header .icon-box:hover h2 {
		opacity: 1;
		right: 27px;
		margin: 0;
		text-align: center;
		border-radius: 30px 0 0 30px;
	}
}

/* [ Pages ] */
/*================================================== */

/*** Homepage ***/

.home .color-block {
	position: fixed;
	height: 200%;
	width: 100%;
	transform: rotate(-15deg);
	left: -83%;
	top: -50%;
}

.home .bg {
	background-image: url(../img/2.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	height: calc(100vh - 80px);
	z-index: 111;
	border-radius: 30px;
	left: 40px;
	top: 40px;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.9);
}

.home .container-home {
	display: flex;
	align-items: center;
	min-height: 100%;
}

.home .home-details > div {
	max-width: 550px;
	margin: 0 auto;
}

.home .home-details h1 {
	font-size: 51px;
	line-height: 62px;
	font-weight: 700;
	margin: 18px 0 10px;
	position: relative;
	padding-left: 70px;
}

.home .home-details h1:before {
	content: "";
	position: absolute;
	left: 0;
	top: 29px;
	height: 4px;
	width: 40px;
	border-radius: 10px;
}

.home .home-details h1 span {
	color: #fff;
	display: block;
}

.home .home-details h6 {
	color: #eee;
	font-size: 22px;
	font-weight: 400;
	display: block;
}

.home .home-details p {
	margin: 15px 0 28px;
	font-size: 16px;
	line-height: 35px;
}

/*** About ***/

.about .main-content {
	padding-bottom: 20px;
}

/* Personal Info List */

.about .about-list li:not(:last-child) {
	padding-bottom: 20px;
}

.about .about-list .title {
	opacity: 0.8;
	text-transform: capitalize;
}

.about .about-list .value {
	font-weight: 600;
}

/* Box Stats */

.about .box-stats {
	padding: 20px 30px 25px 40px;
	border-radius: 5px;
	border: 1px solid #252525;
}

.about .box-stats.with-margin {
	margin-bottom: 30px;
}

.about .box-stats h3 {
	position: relative;
	display: inline-block;
	margin: 0;
	font-size: 50px;
	font-weight: 700;
}

.about .box-stats h3:after {
	content: "+";
	position: absolute;
	right: -24px;
	font-size: 33px;
	font-weight: 300;
	top: 2px;
}

.about .box-stats p {
	padding-left: 45px;
}

/* Resume */

.about .resume-box ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.about .resume-box li {
	position: relative;
	padding: 0 20px 0 60px;
	margin: 0 0 50px;
}

.about .resume-box li:after {
	content: "";
	position: absolute;
	top: 0;
	left: 20px;
	bottom: 0;
	border-left: 1px solid #333;
}

.about .resume-box .icon {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 0;
	right: 0;
	line-height: 40px;
	text-align: center;
	z-index: 1;
	border-radius: 50%;
	color: #fff;
	background-color: #252525;
}

.about .resume-box .time {
	color: #fff;
	font-size: 12px;
	padding: 1px 10px;
	display: inline-block;
	margin-bottom: 12px;
	border-radius: 20px;
	font-weight: 600;
	background-color: #252525;
	opacity: 0.8;
}

.about .resume-box h5 {
	font-size: 18px;
	margin: 7px 0 10px;
}

.about .resume-box p {
	margin: 0;
	color: #eee;
	font-size: 14px;
}

.about .resume-box .place {
	opacity: 0.8;
	font-weight: 600;
	font-size: 15px;
	position: relative;
	padding-left: 26px;
}

.about .resume-box .place:before {
	position: absolute;
	content: "";
	width: 10px;
	height: 2px;
	background-color: #fff;
	left: 7px;
	top: 9px;
	opacity: 0.8;
}

/* Skills */

.about .c100 {
	float: none !important;
	margin: 0 auto;
	background-color: #252525;
}

.about .c100 > span {
	color: #fff;
}

.about .c100:after {
	background-color: #111;
}

/*** Portfolio ***/
.portfolio .main-content {
	padding-bottom: 60px;
	margin-top: -15px;
}

/* Grid */

.portfolio .grid-gallery ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.portfolio .grid-gallery figure {
	margin: 0;
}

.portfolio .grid-gallery figure img {
	display: block;
	width: 100%;
	border-radius: 5px;
	transition: 0.3s;
}

.portfolio .grid-wrap {
	margin: 0 auto;
}

.portfolio .grid {
	margin: 0 auto;
}

.portfolio .grid li {
	width: 33.333333%;
	float: left;
	cursor: pointer;
	padding: 15px;
}

.portfolio .grid figure {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	border-radius: 5px;
	padding: 0;
	position: relative;
	overflow: hidden;
}

.portfolio .grid figure img {
	display: block;
	position: relative;
}

.portfolio .grid li a {
	overflow: hidden;
}

.portfolio .grid li figure div {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.portfolio .grid li figure div span {
	margin: 0;
	text-transform: uppercase;
	color: #fff;
	font-size: 18px;
}

/* Slideshow */

.portfolio .slideshow {
	position: fixed;
	background: rgba(0, 0, 0, 0.85);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1111;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
	text-align: left;
}

.portfolio .slideshow-open .slideshow {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.portfolio .slideshow ul {
	width: 100%;
	height: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translate3d(0, 0, 150px);
	transform: translate3d(0, 0, 150px);
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.portfolio .slideshow ul.animatable > li {
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.portfolio .slideshow-open .slideshow ul {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.portfolio .slideshow ul > li {
	width: 660px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -280px 0 0 -330px;
	visibility: hidden;
}

.portfolio .slideshow ul > li.show {
	visibility: visible;
}

.portfolio .slideshow ul > li:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	z-index: 111;
	border-radius: 10px;
}

.portfolio .slideshow ul > li.current:after {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.3s, visibility 0s 0.3s;
	transition: opacity 0.3s, visibility 0s 0.3s;
}

.portfolio .slideshow figure {
	width: 100%;
	height: 100%;
	background: #252525;
	padding: 30px;
	overflow: auto;
	border-radius: 10px;
}

.portfolio .slideshow a {
	text-decoration: underline;
}

.portfolio .slideshow figcaption {
	margin-bottom: 15px;
}

.portfolio .slideshow figcaption h3 {
	text-transform: uppercase;
	padding: 10px 0 20px;
	font-weight: 700;
	text-align: center;
	font-size: 33px;
}

/* Embedded Videos */

.portfolio .slideshow .videocontainer,
.portfolio .slideshow .responsive-video {
	max-width: 100%;
	border-radius: 4px;
	display: block;
	object-fit: cover;
}

.portfolio .slideshow .videocontainer {
	position: relative;
	padding-bottom: 56.2%;
	padding-top: 0;
	height: 0;
	max-width: 100%;
	overflow: hidden;
	width: 100%;
	display: block;
	margin: 0;
	border-radius: 4px;
}

.portfolio .slideshow .videocontainer iframe,
.portfolio .slideshow .videocontainer object,
.portfolio .slideshow .videocontainer embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

/* Carousel */

.portfolio .slideshow .carousel-indicators {
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	padding: 10px 0;
	margin: 0;
}

.portfolio .slideshow .carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 7px;
	background-color: #fff;
	opacity: 1;
}

/* Navigation */

.portfolio .slideshow nav span {
	position: fixed;
	z-index: 1000;
	text-align: center;
	cursor: pointer;
	padding: 200px 30px;
	color: #fff;
}

.portfolio .slideshow nav span.nav-prev,
.portfolio .slideshow nav span.nav-next {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 41px;
	line-height: 58px;
}

.portfolio .slideshow nav span.nav-prev {
	left: 0;
}

.portfolio .slideshow nav span.nav-next {
	right: 0;
}

.portfolio .slideshow nav span.nav-close {
	top: 30px;
	right: 30px;
	padding: 0;
}

/*** Contact ***/
.contact .main-content {
	padding-bottom: 85px;
}
/* Left Side */

.contact .custom-span-contact {
	font-weight: 600;
	padding-left: 50px;
	line-height: 21px;
	padding-top: 5px;
}

.contact .custom-span-contact i {
	left: 0;
	top: 10px;
	font-size: 33px;
}

.contact .custom-span-contact i.fa-phone-square {
	font-size: 39px;
	top: 7px;
}

.contact .custom-span-contact span {
	text-transform: uppercase;
	opacity: 0.8;
	font-weight: 400;
}

.contact ul.social {
	margin-left: -5px;
}

.contact ul.social li {
	display: inline-block;
}

.contact ul.social li a {
	display: inline-block;
	height: 40px;
	width: 40px;
	line-height: 42px;
	text-align: center;
	color: #fff;
	transition: 0.3s;
	font-size: 17px;
	margin: 0 6px;
	background: #2b2a2a;
	border-radius: 50%;
}
/* Form */
.contact .contactform input[type="text"],
.contact .contactform input[type="email"] {
	border: 1px solid #111;
	width: 100%;
	background: #252525;
	color: #fff;
	padding: 11px 26px;
	margin-bottom: 30px;
	border-radius: 30px;
	outline: none !important;
	transition: 0.3s;
}

.contact .contactform textarea {
	border: 1px solid #111;
	background: #252525;
	color: #fff;
	width: 100%;
	padding: 12px 26px;
	margin-bottom: 20px;
	height: 160px;
	overflow: hidden;
	border-radius: 30px;
	outline: none !important;
	transition: 0.3s;
}

.contact .contactform .form-message {
	margin: 24px 0;
}

.contact .contactform .output_message {
	display: block;
	color: #fff;
	height: 46px;
	line-height: 46px;
	border-radius: 30px;
}

.contact .contactform .output_message.success {
	background: #28a745;
}

.contact .contactform .output_message.success:before {
	font-family: FontAwesome;
	content: "\f14a";
	padding-right: 10px;
}

.contact .contactform .output_message.error {
	background: #dc3545;
}

.contact .contactform .output_message.error:before {
	font-family: FontAwesome;
	content: "\f071";
	padding-right: 10px;
}

/*** Blog ***/

.blog .main-content {
	padding-bottom: 85px;
}

/* Latest Posts */

.blog .post-container {
	display: block;
}

.blog .post-content {
	background-color: #252525;
	padding: 20px 25px 25px 25px;
	border-radius: 0 0 5px 5px;
}

.blog .post-content .entry-header h3 {
	line-height: 26px;
	cursor: pointer;
}

.blog .post-content .entry-header h3 a {
	text-decoration: none;
	transition: 0.5s;
}

.blog .post-thumb {
	border-radius: 5px 5px 0 0;
	cursor: pointer;
}

.blog .post-thumb a {
	border-radius: 5px 5px 0 0;
}

.blog .post-container:hover img {
	transform: scale(1.2);
}

.blog .post-thumb a img {
	border-radius: 5px 5px 0 0;
	transition: 0.3s;
}

.blog .post-content .entry-content p {
	margin: 15px 0 5px;
}

.blog .post-content .entry-header h3 a {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
}

/* pagination */

.blog .pagination {
	margin-bottom: 30px;
}

.blog .page-link {
	color: #fff;
	background-color: #252525;
	border: 1px solid #252525;
	padding: 0;
	margin: 0 10px;
	border-radius: 50% !important;
	width: 43px;
	height: 43px;
	line-height: 43px;
	text-align: center;
	transition: 0.3s;
}

.blog .page-link:focus,
.blog .page-link:active {
	box-shadow: none !important;
}

.blog .page-link:hover {
	color: #fff !important;
}

/* Blog Post */

.blog-post article {
	max-width: 700px;
	margin: 0 auto;
}

.blog-post article h1 {
	font-weight: 600;
	margin: 13px 0 20px;
}

.blog-post article img {
	border-radius: 5px;
	margin-bottom: 20px;
}

.blog-post .meta {
	color: #eee;
	font-size: 13px;
}

.blog-post .meta span {
	padding-right: 15px;
}

.blog-post .meta i {
	padding-right: 3px;
}

/* [ RESPONSIVE DESIGN STYLES ] */

@media (min-width: 1351px) {
	.portfolio .container-portfolio .grid {
		text-align: left;
	}
}

@media (max-width: 1350px) and (min-width: 992px) {
	.container {
		max-width: calc(100% - 195px);
	}

	.portfolio .container-portfolio {
		max-width: calc(100% - 195px);
	}
}

@media (max-width: 1200px) {
	.home .home-details h1 {
		padding-left: 0;
	}
	.home .home-details h1:before {
		display: none;
	}
}

@media (max-width: 1200px) and (min-width: 992px) {
	.home .home-details h1 {
		font-size: 42px;
		line-height: 52px;
	}
}

@media (max-width: 1100px) and (min-width: 992px) {
	.home .home-details > div {
		max-width: 450px;
	}
}

@media (min-width: 1600px) {
	.home .home-details > div {
		max-width: 600px;
	}
}

@media (min-width: 992px) {
	.home .home-details-container {
		width: 100%;
		height: 100vh;
	}

	.home {
		overflow: hidden;
	}
}

/* Mobile  Navigation */

@media (max-width: 991px) {
	.home {
		padding: 25px;
	}

	.home .main-container {
		padding: 30px 0 !important;
	}

	.home .home-details-container {
		margin: 0 auto;
	}

	.home .home-details {
		padding: 0;
	}

	.main-img-mobile {
		border-radius: 50%;
		width: 270px;
		height: 270px;
		border: 4px solid #252525;
		margin: 0 auto 25px;
		display: block;
	}

	.home .home-details h1 {
		font-size: 38px;
		line-height: 48px;
		margin: 29px 0 13px;
	}

	.home .home-details p {
		margin: 10px 0 23px;
		font-size: 15px;
		line-height: 30px;
	}

	#menuToggle {
		display: inline-block;
		position: fixed;
		top: 30px;
		right: 30px;
		z-index: 1111;
		user-select: none;
		padding: 19px 0 0 16px;
		width: 54px;
		height: 54px;
		border-radius: 5px;
		background: #252525;
	}

	#menuToggle input {
		display: flex;
		width: 54px;
		height: 54px;
		position: absolute;
		cursor: pointer;
		opacity: 0;
		z-index: 2;
		top: 0;
		left: 0;
	}

	#menuToggle > span {
		display: flex;
		width: 23px;
		height: 2px;
		margin-bottom: 5px;
		position: relative;
		background: #ffffff;
		border-radius: 3px;
		z-index: 1;
		transform-origin: 5px 0;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
			background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
	}

	#menuToggle > span:first-child {
		transform-origin: 0 0;
	}

	#menuToggle > span:nth-last-child(2) {
		transform-origin: 0 100%;
	}

	#menuToggle input:checked ~ span {
		opacity: 1;
		transform: rotate(45deg) translate(4px, 0);
		background: #fff;
	}

	#menuToggle input:checked ~ span:nth-last-child(3) {
		opacity: 0;
		transform: rotate(0deg) scale(0.2, 0.2);
	}

	#menuToggle input:checked ~ span:nth-last-child(2) {
		transform: rotate(-45deg) translate(2px, 4px);
	}

	#menu {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding-top: 60px;
		background-color: #252525;
		-webkit-font-smoothing: antialiased;
		transform-origin: 0 0;
		transform: translate(-100%, 0);
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
	}

	#menuToggle input:checked ~ ul {
		transform: none;
	}

	#menu li {
		position: relative;
		padding-left: 30px;
	}

	#menu li:after {
		position: absolute;
		content: "";
		height: 1px;
		width: calc(100% - 60px);
		background: #333;
		left: 30px;
	}

	#menu li:last-child:after {
		display: none;
	}

	#menu li.active a span {
		font-weight: 600;
	}

	#menu li a {
		color: #fff;
		text-transform: uppercase;
		transition-delay: 2s;
		font-size: 26px;
		display: block;
		text-decoration: none;
		padding: 14px 0;
		position: relative;
	}

	#menu li a span {
		position: absolute;
		left: 50px;
		font-weight: 400;
	}

	.portfolio .grid li {
		width: 50%;
	}

	.portfolio .grid li figure div {
		display: none !important;
	}

	.portfolio .slideshow {
		background: #252525;
	}

	.portfolio .slideshow nav span {
		padding: 0;
	}

	.portfolio .slideshow ul > li {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;
	}

	.portfolio .slideshow ul > li:after {
		display: none;
	}

	.portfolio .slideshow figure {
		padding: 125px 30px 30px;
		border-radius: 0;
		max-width: 720px;
		margin: 0 auto;
	}

	.portfolio .slideshow nav span.nav-prev,
	.portfolio .slideshow nav span.nav-next {
		top: 35px;
		width: 32px;
		height: 32px;
	}

	.portfolio .slideshow nav span.nav-next {
		right: 30px;
	}

	.portfolio .slideshow nav span.nav-prev {
		left: 30px;
	}

	.portfolio .slideshow nav span.nav-prev img,
	.portfolio .slideshow nav span.nav-next img {
		width: 32px;
		height: 32px;
	}

	.portfolio .slideshow nav span:before {
		display: none;
	}

	.portfolio .slideshow nav span.nav-close {
		top: 35px;
		right: 0;
		left: 0;
		margin: 0 auto;
		width: 32px;
		height: 32px;
		display: block;
		line-height: 0;
	}

	.portfolio .slideshow nav span.nav-close img {
		width: 32px;
		height: 32px;
	}

	.portfolio .slideshow nav {
		position: fixed;
		top: 0;
		width: 100%;
		height: 102px;
		background: #252525;
		z-index: 1;
		border-bottom: 1px solid #333;
	}

	.contact .main-content {
		padding-bottom: 0;
	}
}

@media (max-width: 767px) {
	.home .home-details p {
		font-size: 14px;
		line-height: 28px;
	}
}

@media (max-width: 576px) {
	body {
		font-size: 14px;
	}

	#menuToggle {
		right: 10px;
		top: 4px;
		padding: 19px 0 0 13px;
		width: 49px;
		height: 49px;
	}

	.home #menuToggle {
		right: 25px;
		top: 25px;
		padding: 17px 0 0 13px;
	}

	#menu li {
		padding-left: 25px;
	}

	#menu li a {
		font-size: 18px;
	}

	#menu li:after {
		width: calc(100% - 50px);
		left: 25px;
	}

	#menu li a span {
		left: 35px;
	}

	.main-content {
		padding-top: 85px;
	}

	.main-content > .container {
		padding: 0 25px;
	}

	.main-img-mobile {
		width: 230px;
		height: 230px;
	}

	.home .home-details h1 {
		font-size: 29px;
		line-height: 39px;
		margin: 18px 0 13px;
	}

	.home .home-details h6 {
		font-size: 19px;
	}

	.title-section {
		padding: 16px 25px 14px;
		background: #252525;
		border-bottom: 1px solid #333;
		margin-bottom: 36px;
		position: fixed;
		right: 0;
		left: 0;
		z-index: 11;
		top: 0;
	}

	.title-section h1 {
		font-size: 26px;
	}

	.title-bg {
		display: none;
	}

	.custom-title {
		font-size: 21px;
	}

	.about .main-content {
		padding-bottom: 0;
	}

	.about .resume-box h5 {
		font-size: 17px;
	}

	.about .box-stats {
		padding: 15px 20px 20px 25px;
	}

	.about .c100 {
		transform: scale(0.8);
	}

	.about .box-stats.with-margin {
		margin-bottom: 25px;
	}

	.about .box-stats h3 {
		font-size: 40px;
	}

	.about .box-stats p {
		padding-left: 0;
	}

	.portfolio .main-content {
		padding-bottom: 10px;
		margin-top: -12px;
	}

	.portfolio .grid li {
		width: 100%;
	}

	.portfolio .main-content > .container {
		padding: 0 10px;
	}

	.portfolio .slideshow figure {
		padding: 85px 25px 25px;
	}

	.portfolio .slideshow figcaption h3 {
		font-size: 25px;
	}

	.portfolio .slideshow nav span.nav-prev,
	.portfolio .slideshow nav span.nav-next {
		top: 9px;
		width: 20px;
		height: 20px;
	}

	.portfolio .slideshow nav span.nav-next {
		right: 20px;
	}

	.portfolio .slideshow nav span.nav-prev {
		left: 20px;
	}

	.portfolio .slideshow nav span.nav-prev img,
	.portfolio .slideshow nav span.nav-next img {
		width: 20px;
		height: 20px;
	}

	.portfolio .slideshow nav span.nav-close {
		top: 21px;
		width: 20px;
		height: 20px;
	}

	.portfolio .slideshow nav span.nav-close img {
		width: 20px;
		height: 20px;
	}

	.portfolio .slideshow nav {
		height: 62px;
	}

	.blog .main-content {
		padding-bottom: 55px;
	}

	.blog-post article h1 {
		font-size: 25px;
	}

	.separator.mt-1 {
		margin-top: 35px !important;
	}
}
